$navbar-height: 70px;
$navbar-background-color: #2B2B2B;
$header-height: 132px;
$footer-background-color: #2B2B2B;
$navbar-active-color: #c5a15b;
$footer-color: #fff;
body {
    background: $footer-background-color;
}

#header-main {
    //min-height: $header-height;
    background: #fff;
    overflow: hidden;
    padding-top: $navbar-height;
}

.navbar-main {
    background: $navbar-background-color;
    color: #fff;
    height: $navbar-height;
    z-index: 1000000;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: auto;
    width: auto;
}

#content-main {
    background: #fff;
    position: relative;
    z-index: 100;
    overflow: hidden;
}

#footer {
    background: $footer-background-color;
    color: #ccc;
    min-height: 200px;
    position: relative;
    z-index: 200;
}
#apl-ymap-container {
    overflow: hidden;
    height: 640px;
    width: 100%;
    max-height: 90vh;
    background: #ccc;
    position: relative;
    >:before {
        position: absolute;
        z-index: 999999;
        left: 0;
        bottom: 0;
        height: auto;
        top: 0;
        right: auto;
        width: 100px;
        //background: #000;
        background: linear-gradient(to right, #fff, transparent);
        opacity: .7;
        display: block;
        content: "\f338";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        text-align: center;
        font-size: 100px;
        color: rgba(0,0,0,.2);
        line-height: 530px;
        //pointer-events: none;

    }
    >:after {
        position: absolute;
        z-index: 999999;
        right: 0;
        bottom: 0;
        height: auto;
        top: 0;
        width: 100px;
        left: auto;
        //background: #000;
        background: linear-gradient(to left, #fff, transparent);
        opacity: .7;
        display: block;
        content: "\f338";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        text-align: center;
        font-size: 100px;
        color: rgba(0,0,0,.2);
        line-height: 530px;
    }
}
.apl-ymap-icon {
    width: 55px;
    height: 55px;
    background-size: 35px auto;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
}

.navbar-main-menu {
    display: block;
    padding: 0;
    margin: 0;
    height: $navbar-height;
    li {
        display: block;
        float: left;
        height: $navbar-height;
        padding: 0 15px;
        margin: 0;
        > a, > span {
            display: block;
            overflow: hidden;
            height: $navbar-height;
            line-height: $navbar-height - 1px;
            padding: 0;
            color: #fff;
            cursor: pointer;
        }
        span:hover, a:hover {
            color: $navbar-active-color;
            text-decoration: underline;
        }
        .dropdown-menu {
            background: $navbar-background-color;
            a {
                color: #fff;
                background: inherit !important;
                line-height: 50px;
            }
            a:hover {
                color: $navbar-active-color;
            }
        }
    }
    li:first-child {
        padding-right: 0;
    }
    .navbar-main-menu-home {
        width: 40px;
        background: url("/static/site/img/Kanku_Kyokushin.svg");
        background-size: 24px;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: $navbar-active-color;
    }
}

.sofkk-logo {
    max-height: 80px;
}

.slider-wrapper {
    width: 100%;
}

.bx-wrapper {
    border: 0;
}

.bx-viewport img {
    width: 100%;
}

.navbar-main .fa-bars {
    display: block;
    width: $navbar-height;
    height: $navbar-height;
    line-height: $navbar-height - 1;
    text-align: center;
    cursor: pointer;
    font-size: 32px;
}

#apl-bars-menu {
    .dropdown-menu {
        background: $navbar-background-color;
        a {
            line-height: 48px;
            color: #fff;
        }
        a:hover {
            background: inherit;
            color: $navbar-active-color;
        }
    }
}

.apl-2cols-layout {
    overflow: hidden;
    .apl-main-col {
        overflow: hidden;
    }
    .apl-additional-col {
        overflow: hidden;
    }
}

@media all and (min-width: 768px) {
    .apl-2cols-layout {
        display: flex;
        .apl-main-col {
            flex: 1;
            padding-right: 30px;
        }
        .apl-additional-col {
            flex: 0 0 240px;
        }
    }
}

#vk_groups,
#vk_groups iframe {
    width: 100% !important;
}

.apl-additional-col-banner-place {
    margin: 0 auto;
    max-width: 300px;
    img {
        width: 100%;

    }
}
