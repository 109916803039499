.version {
    content: '1';
}
@import "common";
@import "util";
html {
    width: 100%;
    height: 100%;
}
body {
    font-family: "Open Sans", sans-serif;
    width: 100%;
    height: 100%;
    position: relative;
}
@import "page";
@import "blog";
@import "news";
@import "clubs";
@import "faq";
