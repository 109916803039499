.apl-clubs-block {
    h1 {
        //font-weight: 100;
    }
    .apl-clubs-list-item {
        h2 {
            font-weight: 600;
            a {
                color: inherit!important;
                text-decoration: none!important;
            }
        }
        img {
            max-width: 100%;
        }

        a.btn:hover {
            color: white!important;
        }
    }
    .apl-clubs-list-item-text {

    }
}
